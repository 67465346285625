<template>
  <div class='login-box'>
    <div class='login-container'>
      <transition name="login" mode="out-in">
        <component :is="view"></component>
      </transition>
    </div>
    <div class='beian' style="line-height: 25px;">版权所有©欣奕除疤 备案号：<a href="https://beian.miit.gov.cn/?spm=5176.product-detail.J_9220772140.47.39f92279IxDbHR#/Integrated/index">豫ICP备2023009551号-1</a></div>

  </div>
  
</template>



<script>
import UserLogin from './components/user-login'
import EditPwd from './components/edit-pwd'
export default {
  name: 'login',
  components: {
    UserLogin,
    EditPwd
  },
  data () {
    return {
      view: 'UserLogin'
    }
  },
  methods: {
    handleToggle () {
      if (this.view === 'EditPwd') {
        this.view = 'UserLogin'
        return
      }
      this.view = 'EditPwd'
    }
  }
}
</script>

<style lang='scss' scoped>
.login-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 97%;
  margin: 0 auto;
  background: url("http://8.142.186.111/static/80c7bf823df5ba49bf034350adab352.jpg") no-repeat;
  background-color: $theme-color;
  background-size: 100% 100%;
}
.login-enter-active {
  transform: translateX(-20px);
  opacity: 0;
  transition: all .1s cubic-bezier(0.58, 0.35, 0.79, 0.13);
}

.login-leave-active {
  transform: translateX(20px);
  opacity: 0;
  transition: all .1s cubic-bezier(0.58, 0.35, 0.79, 0.13);

}
.beian{
  width:100%;
  height:3%;
  position:flex;
  background-color: #2e2e2e;
  color:#ffffff;
  text-align: center;
}
.login-box{
  width:100%;
  height:100vh;
}
</style>
