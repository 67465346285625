<template>
    <el-card class="edit-card">
      <span
        class="edit-pwd"
        @click="toggle"
      >  <i class="edit-pwd el-icon-arrow-left"></i>返回 </span>
      
      <el-form
        :model="editForm"
        :rules="editRules"
        ref="editForm"
        class="edit-form"
        label-width="0"
      >
        <el-form-item prop="mobile">
          <el-input
            v-model="editForm.mobile"
            clearable
            auto-complete="off"
            placeholder="请输入手机号码"
          >
            <i slot="prefix" class="el-icon-user"></i>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-input :disabled="codeDis" v-model="editForm.code" placeholder="请输入验证码">
            <el-button
              :disabled="codeDis || timeDis"
              slot="append"
              class="code"
              @click="handleGetCode"
            >
              <span>{{ timeText }}</span>
            </el-button>
          </el-input>
        </el-form-item>

        <el-form-item prop="newPwd">
          <el-input
            v-model="editForm.newPwd"
            clearable
            show-password
            auto-complete="off"
            placeholder="请输入登陆密码"
          >
            <i slot="prefix" class="el-icon-unlock"></i>
          </el-input>
        </el-form-item>
        

        <el-form-item prop="confirmNewPwd">
          <el-input
            v-model="editForm.confirmNewPwd"
            clearable
            show-password
            auto-complete="off"
            placeholder="请再次输入登陆密码"
          >
            <i slot="prefix" class="el-icon-unlock"></i>
          </el-input>
        </el-form-item>

        <el-button class="edit-submit" type="success" @click="handleEditPWD">确认修改</el-button>

      </el-form>
    </el-card>
</template>

<script>
import { getCode, forGetPWD } from '@/api/user'
import { mobileExp } from '@/utils/regexp'
export default {
  name: 'EditPwd',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.editForm.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      timeText: '获取验证码',
      timeDis: false,
      timer: null,
      editForm: {
        mobile: '',
        code: '',
        newPwd: '',
        confirmNewPwd: ''
      },
      editRules: {
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: mobileExp, message: '请输入正确格式', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        newPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        confirmNewPwd: [
          { validator: validatePass, trigger: 'blur' }
        ]
      },
    }
  },
  computed: {
    codeDis () {
      return !mobileExp.test(this.editForm.mobile)
    },
    parent () {
      return this.$parent
    }
  },
  methods: {
    handleGetCode () {
      this.setTime(60)
      
      getCode({
        phone: this.editForm.mobile,
        type: '2'
      })
    },
    hint(){
      this.$confirm(`确定修改为此登录密码吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getLogin()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    handleEditPWD () {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          this.hint()
        }
      })
    },
    getLogin(){
      forGetPWD(this.editForm).then(() => {
        this.toggle()
      })
    },
    toggle () {
      this.parent.handleToggle()
    },
    setTime (countDown) {
      this.timeDis = true
      this.timer && clearInterval(this.timer)
      const setTimeFn = () => {
        if (countDown == 0) {
          this.timeDis = false
          this.timeText = '获取验证码'
        } else {
          this.timeText = `${ countDown }重新发送`
          countDown--
        }
      }
      setTimeFn()
      this.timer = setInterval(setTimeFn, 1000)
    }
  }
}
</script>

<style lang='scss' scoped>
.edit-card {
  width: 400px;
  height: 420px;
  margin: 0 auto;
}
.edit-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 300px;
  height: 340px;
  margin: 20px auto;
}
.edit-pwd {
  font-size: 14px;
  font-weight: 700;
  color: #6a6a6a;
  margin-left: auto;
  cursor: pointer;
}
.el-input-group__append .code {
  color: #fff;
  background-color: #2941ebc4;
  border-radius: 0;
  line-height: 16px;
}
.edit-submit {
  @include successBtn;
  margin-bottom: 30px;
}
</style>
