<template>
    <el-card class="login-card">

      <h2 class="login-title">{{ website.title }}</h2>

      <el-form
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
        class="login-form"
        label-width="0"
      >
        <el-form-item prop="type">
          <el-radio-group v-model.trim="loginForm.type"
            @keyup.enter.native="handleLogin">
            <el-radio :label="3">员工账户</el-radio>
            <el-radio :label="1">系统用户</el-radio>
            <el-radio :label="2">店铺账户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="username">
          <el-input
            v-model.trim="loginForm.username"
            @keyup.enter.native="handleLogin"
            clearable
            auto-complete="off"
            placeholder="请输入用户名称"
          >
            <i slot="prefix" class="el-icon-user"></i>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model.trim="loginForm.password"
            @keyup.enter.native="handleLogin"
            clearable
            show-password
            auto-complete="off"
            placeholder="请输入登陆密码"
          >
            <i slot="prefix" class="el-icon-unlock"></i>
          </el-input>
        </el-form-item>

        <el-button class="login-submit" @click="handleLogin">登录</el-button>

        <span class="edit-pwd" @click="this.$parent.handleToggle">忘记密码<i class="edit-pwd el-icon-arrow-right"></i></span>
      </el-form>
    </el-card>
</template>

<script>
export default {
  name: 'UserLogin',
  data () {
    return {
      loginForm: {
        type: 3,
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 1, message: '密码长度最少为6位', trigger: 'blur' }
        ]
      },
      redirect: null,
      routerQuery: null
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.routerQuery = this.getRouterQuery(query)
        }
      },
      immediate: true
    }
  },
  methods: {
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '登录中,请稍后...',
            spinner: 'el-icon-loading'
          })
          this.$store.dispatch('loginByToken', this.loginForm)
            .then(() => {
              this.$router.push({
                path: this.redirect || '/',
                query: this.routerQuery
              })
              loading.close()
            })
            .catch((e) => {
              loading.close()
              // console.log(e)
              //  this.$message({
              //   message: "请添加商品",
              //   type: "error"
              // });
            })
        }
      })
    },
    getRouterQuery(query) {
      return Object.keys(query).reduce((res, key) => {
        if (key !== 'redirect') {
          res[cur] = query[key]
        }
        return res
      }, {})
    }
  }
}
</script>

<style lang='scss' scoped>

.login-card {
  width: 400px;
  height: 460px;
  margin: 0 auto;
}
.login-title {
  color: $theme-color;
  font-size: 22px;
  text-align: center;
  letter-spacing: 4px;
}
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 300px;
  height: 340px;
  margin: 20px auto;
}
.edit-pwd {
  font-size: 14px;
  font-weight: 700;
  color: #6a6a6a;
  margin-left: auto;
  cursor: pointer;
}
.login-submit {
  @include successBtn;
  margin-bottom: 30px;
}
</style>
